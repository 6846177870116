<template>
  <div class="culture">
    <Top v-if="columnList.img_info_list" :img="'/gsmm/api/v1/'+columnList.img_info_list[0].url" :ch="ch"/>
    <Title :ch="ch" :en="en"/>
    <div class="content">
      {{ columnList.description }}
    </div>
    <div class="center">
      <Menu :menuList="columnList.son_tree_column" class="menu"/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import Top from '../components/top.vue'
import Menu from '../components/menu.vue'
import Title from '../components/title.vue'
import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      ch: '数字赋能',
      en: 'BIG DATA',
    };
  },
  components: {
    Top,
    Menu,
    Title
  },
  computed: {
    ...mapState(['columnList'])
  },
  created() {

  },
  mounted() {
    this.handleColumn(6)
  },
  methods: {
    ...mapActions(['handleColumn']),
  }
};
</script>

<style scoped lang='less'>
.culture {
  width: 100%;
  background-image: url('../../assets/bg.png');
  padding-bottom: 50px;
}

.content {
  padding: 20px 30%;
}

.center {
  padding: 0 18%;
}
</style>
