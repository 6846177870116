import { render, staticRenderFns } from "./empowerment.vue?vue&type=template&id=274bfcd5&scoped=true&"
import script from "./empowerment.vue?vue&type=script&lang=js&"
export * from "./empowerment.vue?vue&type=script&lang=js&"
import style0 from "./empowerment.vue?vue&type=style&index=0&id=274bfcd5&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274bfcd5",
  null
  
)

export default component.exports